<template>
  <div>
    <header>
      <el-button size="small" type="primary" @click="openDialog('add')"
        >新开赛季</el-button
      >
    </header>
    <el-dialog
      :close-on-click-modal="false"
      append-to-body
      :title="title"
      width="60%"
      :visible.sync="dialogVisible"
    >
      <el-form :inline="true" :model="seasonForm" class="demo-form-inline">
        <el-row type="flex" style="justify-content: start">
          <el-form-item label="赛季名">
            <el-input
              size="mini"
              v-model="seasonForm.name"
              placeholder="输入赛季名"
            ></el-input>
          </el-form-item>
          <el-form-item label="开始时间">
            <el-input
              size="mini"
              v-model="seasonForm.opentime"
              placeholder="2024-01-24T02:30:10.656Z"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="注意:">
            <p style="color: red; font-weight: bold">
              时间严格按下面这个格式输入
            </p>
          </el-form-item>
        </el-row>
        <el-row>
          <p>格式：年份-月份-日T小时:分钟:秒.656Z</p>
          <p style="color: red">示例：2024-01-24T02:30:10.656Z</p>
        </el-row>
        <el-row>
          <el-form-item label="报名是否结束">
            <el-radio-group v-model="seasonForm.is_over" size="small">
              <el-radio-button label="0">未开始</el-radio-button>
              <el-radio-button label="1">报名中</el-radio-button>
              <el-radio-button label="2">报名结束</el-radio-button>
              <el-radio-button label="3">进行中</el-radio-button>
              <el-radio-button label="4">已结束</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-row>

        <el-form-item>
          <el-button
            v-if="type === 'add'"
            type="primary"
            @click="setRules"
            size="mini"
            >设置赛规</el-button
          >
          <el-button
            v-if="type === 'add'"
            type="primary"
            @click="pushNewEvents"
            size="mini"
            >发布</el-button
          >
          <el-button
            v-else
            type="primary"
            @click="updateItem(seasonForm)"
            size="mini"
            >更新</el-button
          >
        </el-form-item>
        <el-dialog
          :modal="false"
          :visible.sync="diaVisible"
          title="赛季规则"
          @close="handleClose"
          :close-on-click-modal="false"
        >
          <v-md-editor v-model="rules" height="500px"></v-md-editor>
        </el-dialog>
      </el-form>
    </el-dialog>
    <baseTable v-loading="loading" :data="events" :column="tableProps">
      <template #projectHeader="{ data }">
        <div>
          <span>赛季名</span>
          <span class="fontWeight">{{ data.name }}</span>
        </div>
        <div>
          <span
            ><i
              class="el-icon-edit"
              style="color: rgb(91, 182, 242); font-size: 16px; cursor: pointer"
              @click="handleUpdate(data)"
            ></i
          ></span>
          <i
            class="el-icon-delete"
            style="color: red; font-size: 16px; cursor: pointer"
            @click="deleteItem(data.name)"
          ></i>
        </div>
      </template>
      <template #opentime="{ data }">
        {{ new Date(data.opentime) | parseTime("{y}-{m}-{d}") }}
      </template>
      <template #status="{ data }">
        <el-tag :type="data.is_over ? 'danger' : 'primary'">{{
          data.is_over ? "已结束" : "进行中"
        }}</el-tag>
      </template>
      <template #rule="{ data }">
        <a
          class="a-text"
          target="_blank"
          :href="data.events_rule_uri"
          v-if="data.events_rule_uri"
          >详情</a
        >
        <span v-else>暂无规则</span>
      </template>
    </baseTable>
  </div>
</template>

<script>
import {
  pushNewEvents,
  getAllEvents,
  deleteEvents,
  updateEvents,
} from "@/api/gameSeason/index";

import baseTable from "@/components/baseTable.vue";
export default {
  name: "SetSeason",
  data() {
    return {
      seasonForm: {
        name: "",
        is_over: false,
        opentime: new Date(),
      },
      type: "",
      events: [],
      loading: false,
      diaVisible: false,
      dialogVisible: false, //新增/编辑窗口
      rules: "",
      title: "新增赛季",
      tableProps: [
        {
          type: "index",
          label: "序号",
          props: "index",
          style: {
            textAlign: "center",
            minWidth: "80px",
          },
        },
        {
          type: "opentime",
          label: "开启时间",
          prop: "opentime",
          slot: true,
          style: {
            textAlign: "left",
            width: "400px",
          },
        },
        {
          type: "status",
          label: "状态",
          prop: "status",
          slot: true,
          style: {
            textAlign: "center",
            minWidth: "100px",
          },
        },
        {
          type: "rule",
          label: "规则",
          prop: "rule",
          slot: true,
          style: {
            textAlign: "center",
            minWidth: "120px",
          },
        },
      ],
    };
  },
  components: {
    baseTable,
  },
  mounted() {
    this.initEvents();
  },
  methods: {
    handleUpdate(row) {
      this.openDialog("edit");
      this.seasonForm = {
        ...row,
      };
    },
    openDialog(type) {
      this.dialogVisible = true;
      this.type = type;
      if (type === "add") {
        this.seasonForm = {};
      }
    },
    pushNewEvents() {
      if (this.rules === "") {
        this.diaVisible = true;
        this.$message.warning("请先设置赛季规则");
      } else {
        pushNewEvents(
          this.seasonForm.name,
          this.seasonForm.is_over,
          this.seasonForm.opentime,
          this.rules
        )
          .then((res) => {
            this.$message.success(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    initEvents() {
      this.loading = true;
      getAllEvents()
        .then((res) => {
          let { data } = res;
          this.events = data;
          for (var i = 0; i < data.length; i++) {
            const source = {
              isEdit: false,
            };
            this.events[i] = Object.assign(this.events[i], source);
          }
          setTimeout(() => {
            this.loading = false;
          }, 800);
        })

        .catch(() => {
          this.$message.error("服务器异常，请联系管理员！");
          setTimeout(() => {
            this.loading = false;
          }, 800);
        });
    },
    deleteItem(name) {
      this.$confirm("是否删除赛季?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteEvents(name)
            .then(() => {
              this.$message.success("删除成功！");
              this.initEvents();
            })
            .catch(() => {
              this.$message.error("服务器异常，请联系管理员！");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    toggleIsEdit(isEdit, index) {
      this.events[index].isEdit = !isEdit;
      this.events = JSON.parse(JSON.stringify(this.events));
    },
    updateItem(row) {
      updateEvents(row.name, row)
        .then(() => {
          this.dialogVisible = false;
          this.$message.success("更新成功！");
          this.initEvents();
        })
        .catch(() => {
          this.$message.error("服务器异常！");
        });
    },
    // 设置规则
    setRules() {
      this.diaVisible = true;
    },
    handleClose() {
      this.diaVisible = false;
    },
  },
};
</script>

<style scoped lang="less">
header {
  margin: 0 0 12px;
}
.fontWeight {
  font-weight: bold;
}
.a-text {
  color: rgb(161, 175, 241);
  text-decoration: underline;
}
</style>
